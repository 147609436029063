<template>
  <div ref="bot">
  </div>
</template>

<script>
import Utils from "@/mixins/utils";

export default {
  name: "DocsBot",
  props: [],
  data() {
    return {
    };
  },
  mixins: [Utils],
  mounted() {
    this.show();
  },
  methods: {
    async show() {
      const r = await fetch('/docsbot.txt');
      const html = `<body>${await r.text()}</body>`;
      const iframe = document.createElement("iframe");
      iframe.style.position = 'absolute';
      iframe.style.border = '0px solid #ccc';
      iframe.style.width = '100%';
      iframe.style.height = '100%';
      this.$refs.bot.appendChild(iframe);
      const iframeDocument = iframe.contentWindow.document;
      iframeDocument.open();
      iframeDocument.write(html);
      iframeDocument.close();
      setTimeout(() => {
        iframe.contentWindow.DocsBotAI.open();
      }, 1000);
    },
  },
};
</script>

<style scoped>
</style>
