<template>
  <div>
    <div class="d-flex container">
      <manual-link :href="manuallink">{{ t('AIアドバイザー') }}</manual-link>
    </div>
    <div class="iframe-wrapper">
      <docs-bot></docs-bot>
    </div>
  </div>
</template>
<script>
import AuthUtil from '@/mixins/authutil';
import Utils from '@/mixins/utils';
import ManualLink from '@/components/ManualLink.vue';
import DocsBot from '@/components/DocsBot.vue';

const MANUAL_JP = 'https://pro.eresa.jp/function/aiadviser/';
const MANUAL_COM = 'https://eresa.io/function/aiadviser/';

export default {
  name: 'AiAdvisor',
  components: {
    ManualLink,
    DocsBot,
  },
  mixins: [AuthUtil, Utils],
  data() {
    return {
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
    };
  },
  async mounted() {
    await this.validateSubscriber();
  },
  methods: {
  },
};
</script>

<style scoped>
.iframe-wrapper {
  position: relative;
  padding-bottom: 100%;
  top: -20px;
}

@media (orientation: landscape){
  .iframe-wrapper {
    padding-bottom: 40%;
  }
}

@media (orientation: portrait){
  .iframe-wrapper {
    padding-bottom: 160%;
  }
}
</style>
